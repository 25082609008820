/****************************************************

    Utilities - Properties

****************************************************/
import { convertObjectToCamelCase } from "."
import { LatLng } from "../components/PropertyLocation/PropertyLocation"
import { UnitModel } from "../models/component"
import { PropertyModel } from "../models/page"
import { filterBoolean } from "./arrays"

const now = new Date(),
  month = ("0" + (now.getMonth() + 1)).slice(-2),
  day = ("0" + now.getDate()).slice(-2),
  year = now.getFullYear(),
  nowDateString = [year, month, day].join("/")

/****************************************************
    Get property details - Yardi data with overrides
****************************************************/
export const getPropertyDetails = (property?: PropertyModel) => {
  if (!property) return undefined
  const data = convertObjectToCamelCase(property.yardiData)
  // console.log("data: ", data)

  const propertyName = property.propertyName || data.propertyName
  const streetAddress1 = property.streetAddress1 || data.streetAddress1
  const streetAddress2 = property.streetAddress2 || data.streetAddress2
  const city = property.city || data.city
  const state = property.state || data.state
  const postcode = property.postcode || data.postcode
  const propertyType = property.propertyType || data.propertyType

  return {
    propertyName,
    streetAddress1,
    streetAddress2,
    city,
    state,
    postcode,
    propertyType,
  }
}

/****************************************************
    Get unit details - Yardi data with overrides
****************************************************/
export const getUnitDetails = (unit?: UnitModel): UnitModel | undefined => {
  if (!unit) return undefined
  const data: UnitModel = convertObjectToCamelCase(unit.yardiData)
  // console.log("data: ", data)

  return {
    ...unit,
    id: unit.unitId || data.unitCode || "",
    unitId: unit.unitId || data.unitCode,
    unitName: unit.unitName || convertName(unit.unitId || data.unitCode),
    unitType: unit.unitType || convertUnitType(data.unitType),
    building: unit.building || data.building,
    floor:
      unit.floor || convertFloor(data.floor) || convertFloor(data.unitCode),
    rentalType: unit.rentalType || data.rentalType,
    area: unit.area || data.area,
    marketRent: Number(unit.marketRent || data.marketRent).toFixed(2),
    dateLeased:
      convertCmsDate(unit.dateLeased) || convertYardiDate(data.dateLeased),
    availabilityDate:
      convertCmsDate(unit.availabilityDate) ||
      convertYardiDate(data.availabilityDate) ||
      nowDateString,
  }
}

const convertName = (code?: string) => {
  if (!code) return undefined

  if (code === "Lower_GR") return "Lower Ground"
  if (code === "Upper_GR") return "Upper Ground Floor"
  if (code === "Roof") return "Rooftop"
  if (code === "WHOLE") return "Warehouse"

  let output = code.trim()
  if (/^\d+$/.test(output)) return `Warehouse ${output}`

  return output
    .replace("BLDNG_", "Building ")
    .replace("HS_", "Hardstand ")
    .replace("OFF_", "Unit ")
    .replace("OF_", "Unit ")
    .replace("O_", "Unit ")
    .replace("RET_", "Shop ")
    .replace("WH_", "Warehouse ")
    .replace(/_/, " ")
    .replace(/  /, " ")
}

const convertFloor = (floor?: string) => {
  return convertName(floor)
}

const convertUnitType = (unitType?: string) => {
  if (unitType === "BASE") return "Basement"
  if (unitType === "CAFE") return "Cafe"
  if (unitType === "COLD") return "Cold Store"
  if (unitType === "COMM") return "Commercial"
  if (unitType === "CP") return "Parking"
  if (unitType === "HS") return "Hardstand"
  if (unitType === "IND") return "Industrial"
  if (unitType === "LOBBY") return "Lobby"
  if (unitType === "MANUF") return "Manufacturing"
  if (unitType === "OFF") return "Office"
  if (unitType === "RET") return "Retail"
  if (unitType === "SIGN") return "Signage"
  if (unitType === "STOR") return "Storage"
  if (unitType === "SUNDRY") return "Sundry"
  if (unitType === "TELCO") return "Telecom"
  if (unitType === "WARE") return "Warehouse"
  return undefined
}

const convertYardiDate = (date?: string) => {
  if (!date) return undefined
  if (date.indexOf("/") === 4) return convertDoubleDigitDate(date)
  const dateParts = date.split("/")
  return convertDoubleDigitDate(
    `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`,
  )
}

const convertCmsDate = (date?: string) => {
  if (!date) return undefined
  if (date.indexOf("/") === 4) return convertDoubleDigitDate(date)
  date = date.split("T")[0] // remove time value if part of date
  return convertDoubleDigitDate(date.replace(/-/g, "/"))
}

const convertDoubleDigitDate = (date: string) => {
  if (date.indexOf("/") === 4) return date
  return date
    .split("/")
    .map(x => (x.length < 2 ? "0" + x : x))
    .join("/")
}

/******************************************************
    Get units to display in availability table
******************************************************/
export const getDisplayUnits = (units?: UnitModel[]) => {
  if (!units) return undefined

  // make sure units have been processed
  const data = units.map(x => getUnitDetails(x)).filter(filterBoolean)

  // get displayable units
  const displayUnits = data
    ?.map(item => {
      if (!item.showUnit) return null
      if (!item.area) return null
      if (!item.availabilityDate) return null
      return item
    })
    .filter(filterBoolean)

  // sort units by availability date
  const sorted = displayUnits.sort((a, b) => {
    if (!a.availabilityDate) return -1
    if (!b.availabilityDate) return 1
    return a.availabilityDate < b.availabilityDate ? -1 : 1
  })

  return sorted
}

/******************************************************
    Get property name
******************************************************/
export const getPropertyName = (property?: PropertyModel) => {
  if (!property) return undefined
  const details = getPropertyDetails(property)

  return (
    details?.propertyName ||
    details?.streetAddress2 ||
    details?.streetAddress1 ||
    ""
  )
}

/******************************************************
    Get property address
******************************************************/
export const getPropertyAddress = (property?: PropertyModel) => {
  if (!property) return undefined
  const details = getPropertyDetails(property)
  // console.log("details: ", details)
  const state = [details?.state, details?.postcode]
    .filter(filterBoolean)
    .join(" ")
  const address = [
    details?.streetAddress2 || details?.streetAddress1,
    details?.city,
    state,
  ]
    .map(x => x?.trim())
    .filter(filterBoolean)
  return address.length ? address.join(", ") : undefined
}

/******************************************************
    Get property certification
******************************************************/
export const getPropertyCertifications = (property?: PropertyModel) => {
  if (!property) return undefined

  const parts = property.certifications || []
  if (!parts.length) return undefined

  const last = parts.length > 1 ? parts.pop() : undefined
  return `${parts.join(", ")} ${last ? ` and ${last}` : ""} certified`.replace(
    / +/,
    " ",
  )
}

/******************************************************
    Get property type
******************************************************/
export const getPropertyType = (property?: PropertyModel) => {
  if (!property) return undefined
  const details = getPropertyDetails(property)

  if (details?.propertyType) return details.propertyType
  if (!!property?.units?.length) {
    return property?.units
      .map((x: UnitModel) => getUnitDetails(x))
      .map(x => x?.rentalType)
      .filter(filterBoolean)
      .pop()
  }
  return undefined
}

/******************************************************
    Get property size - using units
******************************************************/
export const getPropertySize = (units: UnitModel[] = []) => {
  if (!units?.length) return undefined
  const { min, sum } = getUnitSizeRange(units)

  if (!min || !sum) return undefined
  if (min === sum) return `${min.toLocaleString()} sqm`
  return `${min.toLocaleString()} - ${sum.toLocaleString()} sqm`
}

export const getUnitSizeRange = (units: UnitModel[] = []) => {
  if (!units?.length)
    return {
      min: undefined,
      sum: undefined,
    }

  const sizes = units
    .filter(x => x.showUnit)
    .map(x => Math.trunc(getUnitDetails(x)?.area || 0))
    .filter(filterBoolean)
    .sort((a, b) => a - b)
  return {
    min: sizes.length ? sizes[0] : undefined,
    sum: sizes.reduce((partialSum, a) => partialSum + a, 0),
  }
}

/******************************************************
    Get property area - using property min/max fields
******************************************************/
export const getPropertyArea = (
  min: number | undefined,
  max: number | undefined,
) => {
  if (!min && !max) return undefined
  if (!min) return `${max?.toLocaleString()} sqm`
  if (!max) return `${min.toLocaleString()} sqm`
  if (min === max) return `${min.toLocaleString()} sqm`
  return `${min.toLocaleString()} - ${max.toLocaleString()} sqm`
}

/******************************************************
    Maps
******************************************************/
export const isValidLat = (lat?: number) => lat && lat >= -90 && lat <= 90
export const isValidLng = (lng?: number) => lng && lng >= -180 && lng <= 180

// haversine formula - easier than including the google.maps.geometry library
export const getDistanceInKm = (origin: LatLng, destination: LatLng) => {
  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(destination.lat - origin.lat) // deg2rad below
  var dLon = deg2rad(destination.lng - origin.lng)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(origin.lat)) *
      Math.cos(deg2rad(destination.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

/******************************************************


    DATES

    
******************************************************/
/******************************************************
    Check if Fully Leased
******************************************************/
export const checkFullyLeased = (property?: PropertyModel) => {
  return !!property?.fullyLeased
}

/******************************************************
    Get property availability
******************************************************/
export const currentAvailabilityDate = (property: PropertyModel) => {
  if (!property) return undefined

  // fully leased
  if (checkFullyLeased(property)) return undefined

  // development with label
  if (property.development && property.availabilityLabel) return undefined

  // non-yardi
  if (property.development || property.nonYardi)
    return property.availabilityDate || nowDateString

  // properties
  if (!property.units?.length) return undefined
  return getUnitDetails(earliestAvailability(property.units))?.availabilityDate
}

export const currentAvailability = (property: PropertyModel) => {
  if (!property) return undefined

  // fully leased
  if (checkFullyLeased(property))
    return property.availabilityLabel || "Fully leased"

  // development with label
  if (property.development && property.availabilityLabel)
    return property.availabilityLabel

  return formatAvailability(currentAvailabilityDate(property))
}

export const getUnitAvailability = (unit: UnitModel | undefined) => {
  if (!unit) return undefined

  const details = getUnitDetails(unit)
  if (!details?.availabilityDate) return undefined

  return formatAvailability(details?.availabilityDate)
}

export const earliestAvailability = (units: UnitModel[] = []) => {
  if (!units?.length) return undefined
  const sorted =
    units
      .filter(x => x.showUnit)
      .map(x => getUnitDetails(x))
      .sort((a, b) =>
        new Date(a?.availabilityDate || "") <
        new Date(b?.availabilityDate || "")
          ? -1
          : 1,
      ) || []
  return sorted[0]
}

/******************************************************
    Is the unit available now
******************************************************/
// export const isUnitAvailableNow = (unit: UnitModel) => {
//   if (!unit || !unit.showUnit) return undefined
//   const details = getUnitDetails(unit)
//   if (!details?.availabilityDate) return false
//   return new Date(details?.availabilityDate) < new Date()
// }

/******************************************************
    Get the word version of the unit availability
******************************************************/
// export const getUnitAvailabilityTerm = (unit?: UnitModel) => {
//   if (!unit) return undefined

//   const details = getUnitDetails(unit)
//   if (!details?.availabilityDate) return ""

//   const now = new Date()
//   const date = new Date(details?.availabilityDate)
//   const sixMonths = addMonths(now, 6)
//   const twelveMonths = addMonths(now, 12)

//   if (date < now) return "Available now"
//   if (date < sixMonths) return "Within 6 months"
//   if (date < twelveMonths) return "Within 6-12 months"
//   return "12 months +"
// }

const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month, 0).getDate()

export const addMonths = (input: Date, months: number) => {
  const date = new Date(input)
  date.setDate(1)
  date.setMonth(date.getMonth() + months)
  date.setDate(
    Math.min(
      input.getDate(),
      getDaysInMonth(date.getFullYear(), date.getMonth() + 1),
    ),
  )
  return date
}

export const formatAvailability = (dateString?: string | undefined) => {
  if (!dateString) return undefined

  const now = new Date()
  const date = new Date(dateString + " 0:00:00")

  if (isNaN(date.getTime())) return undefined
  if (date <= now) return "Available now"

  const month = date.getMonth()
  const quarter = Math.floor(month / 3) + 1
  return `Q${quarter} ${date.getFullYear()}`
}
