import React, { useEffect, useReducer } from "react"
import { PropertyModel } from "../../models/page"
import { fetchCart } from "../../utils/cart"
import { CartContext, initialCartState } from "./CartContext"
import CartReducer from "./CartReducer"

interface Props {
  children?: React.ReactElement
}

const CartState = ({ children }: Props) => {
  const [state, dispatch] = useReducer(CartReducer, initialCartState)

  useEffect(() => {
    fetchCart()
      .then(response => {
        if (Array.isArray(response)) return response
        return (response as Response)?.json()
      })
      .then(data => {
        if (data.message) {
          loadCart(undefined)
          throw data
        } else {
          loadCart(data as any as string[])
        }
      })
      .catch(error => {
        loadCart(undefined)
        console.log("Error: ", error)
      })
  }, [])

  const loadCart = (payload: string[] | undefined) => {
    dispatch({ type: "LOAD_CART", payload })
  }

  const addToCart = (payload: PropertyModel) => {
    dispatch({ type: "ADD_TO_CART", payload })
  }

  const removeFromCart = (payload: number) => {
    dispatch({ type: "REMOVE_ITEM", payload })
  }

  const clearCart = () => {
    dispatch({ type: "CLEAR", payload: undefined })
  }

  return (
    // Add the functions that have been defined above into the Context provider, and pass on to the children
    <CartContext.Provider
      value={{
        loadCart,
        addToCart,
        removeFromCart,
        clearCart,
        ...state,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartState
