import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { CaseStudyModel } from "../../models/page"
import { cleanApiURL } from "../../utils/links"

type ContextModel = {
  caseStudyItems: CaseStudyModel[]
  loaded?: boolean
}

const defaultContext: ContextModel = {
  caseStudyItems: [],
  loaded: false,
}

const initialState = {
  caseStudyData: defaultContext,
  setCaseStudyData: (data: ContextModel) => console.log(data),
}
const Context = createContext(initialState)
export const useCaseStudies = () => useContext(Context)

const API_URL = cleanApiURL(process.env.GATSBY_API_DATA + "/api/getCaseStudies")

export const CaseStudyProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [caseStudyData, setCaseStudyData] = useState(initialState.caseStudyData)
  const ContextValue = useMemo(
    () => ({ caseStudyData, setCaseStudyData }),
    [caseStudyData]
  )

  useEffect(() => {
    if (API_URL)
      fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          preview:
            process.env.GATSBY_CONTENTFUL_HOST === "preview.contentful.com",
        }),
      })
        .then(response => response.json())
        .then(data => {
          setCaseStudyData({
            caseStudyItems: data,
            loaded: true,
          })
        })
        .catch(error => {
          setCaseStudyData({
            caseStudyItems: [],
            loaded: true,
          })
          console.log("Error: ", error)
        })
  }, [])

  return <Context.Provider value={ContextValue}>{children}</Context.Provider>
}

export default CaseStudyProvider
