import React from "react"

interface Props {}

export const SvgSprite: React.FC<Props> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="visuallyHidden"
    aria-hidden="true"
  >
    <symbol id="icon-close" viewBox="0 0 24 25">
      <path
        d="M17 7.5L7 17.5M7 7.5L17 17.5"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretUp" viewBox="0 0 31 31">
      <path
        d="M18 15L12 9L6 15"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretLeft" viewBox="0 0 31 31">
      <path
        d="M19.375 7.75L11.625 15.5L19.375 23.25"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretRight" viewBox="0 0 31 31">
      <path
        d="M11.625 23.25L19.375 15.5L11.625 7.75"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-caretDown" viewBox="0 0 31 31">
      <path
        d="M8 12.5L16 20.5L24 12.5"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </symbol>

    <symbol id="icon-phone" viewBox="0 0 16 16">
      <path
        d="M5.58685 5.90223C6.05085 6.86865 6.68337 7.77441 7.48443 8.57546C8.28548 9.37651 9.19124 10.009 10.1577 10.473C10.2408 10.5129 10.2823 10.5329 10.3349 10.5482C10.5218 10.6027 10.7513 10.5636 10.9096 10.4502C10.9542 10.4183 10.9923 10.3802 11.0685 10.304C11.3016 10.071 11.4181 9.95443 11.5353 9.87824C11.9772 9.59091 12.5469 9.59091 12.9889 9.87824C13.106 9.95443 13.2226 10.071 13.4556 10.304L13.5856 10.4339C13.9398 10.7882 14.117 10.9654 14.2132 11.1556C14.4046 11.534 14.4046 11.9809 14.2132 12.3592C14.117 12.5495 13.9399 12.7266 13.5856 13.0809L13.4805 13.186C13.1274 13.5391 12.9508 13.7156 12.7108 13.8505C12.4445 14.0001 12.0308 14.1077 11.7253 14.1068C11.45 14.1059 11.2619 14.0525 10.8856 13.9457C8.86333 13.3718 6.95509 12.2888 5.36311 10.6968C3.77112 9.10479 2.68814 7.19655 2.11416 5.17429C2.00735 4.79799 1.95395 4.60984 1.95313 4.33455C1.95222 4.02906 2.0598 3.6154 2.20941 3.34907C2.34424 3.10904 2.52078 2.9325 2.87386 2.57942L2.97895 2.47433C3.33325 2.12004 3.5104 1.94289 3.70065 1.84666C4.07903 1.65528 4.52587 1.65528 4.90424 1.84666C5.0945 1.94289 5.27164 2.12004 5.62594 2.47433L5.75585 2.60424C5.98892 2.83732 6.10546 2.95385 6.18165 3.07104C6.46898 3.51296 6.46898 4.08268 6.18165 4.52461C6.10546 4.6418 5.98892 4.75833 5.75585 4.9914C5.67964 5.06761 5.64154 5.10571 5.60965 5.15026C5.4963 5.30854 5.45717 5.53805 5.51165 5.72495C5.52698 5.77754 5.54694 5.81911 5.58685 5.90223Z"
        stroke={"inherit"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-mobile" viewBox="0 0 16 16">
      <path
        d="M9.99992 1.33398V2.26732C9.99992 2.64069 9.99992 2.82737 9.92726 2.96998C9.86334 3.09542 9.76135 3.19741 9.63591 3.26132C9.49331 3.33398 9.30662 3.33398 8.93325 3.33398H7.06659C6.69322 3.33398 6.50653 3.33398 6.36393 3.26132C6.23848 3.19741 6.1365 3.09542 6.07258 2.96998C5.99992 2.82737 5.99992 2.64069 5.99992 2.26732V1.33398M5.46659 14.6673H10.5333C11.28 14.6673 11.6534 14.6673 11.9386 14.522C12.1895 14.3942 12.3934 14.1902 12.5213 13.9393C12.6666 13.6541 12.6666 13.2807 12.6666 12.534V3.46732C12.6666 2.72058 12.6666 2.34721 12.5213 2.062C12.3934 1.81111 12.1895 1.60714 11.9386 1.47931C11.6534 1.33398 11.28 1.33398 10.5333 1.33398H5.46659C4.71985 1.33398 4.34648 1.33398 4.06126 1.47931C3.81038 1.60714 3.60641 1.81111 3.47858 2.062C3.33325 2.34721 3.33325 2.72058 3.33325 3.46732V12.534C3.33325 13.2807 3.33325 13.6541 3.47858 13.9393C3.60641 14.1902 3.81038 14.3942 4.06126 14.522C4.34648 14.6673 4.71985 14.6673 5.46659 14.6673Z"
        stroke={"inherit"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-email" viewBox="0 0 16 16">
      <path
        d="M1.33325 4.66602L6.77653 8.47631C7.21731 8.78486 7.4377 8.93913 7.67743 8.99889C7.88918 9.05167 8.11066 9.05167 8.32241 8.99889C8.56213 8.93913 8.78252 8.78486 9.2233 8.47631L14.6666 4.66602M4.53325 13.3327H11.4666C12.5867 13.3327 13.1467 13.3327 13.5746 13.1147C13.9509 12.9229 14.2569 12.617 14.4486 12.2407C14.6666 11.8128 14.6666 11.2528 14.6666 10.1327V5.86602C14.6666 4.74591 14.6666 4.18586 14.4486 3.75803C14.2569 3.38171 13.9509 3.07575 13.5746 2.884C13.1467 2.66602 12.5867 2.66602 11.4666 2.66602H4.53325C3.41315 2.66602 2.85309 2.66602 2.42527 2.884C2.04895 3.07575 1.74299 3.38171 1.55124 3.75803C1.33325 4.18586 1.33325 4.74591 1.33325 5.86602V10.1327C1.33325 11.2528 1.33325 11.8128 1.55124 12.2407C1.74299 12.617 2.04895 12.9229 2.42527 13.1147C2.85309 13.3327 3.41315 13.3327 4.53325 13.3327Z"
        stroke={"inherit"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-floorPlanThin" viewBox="0 0 24 24">
      <path
        d="M16 1H23V13M13 5L8 1H1V23H10M10 23H23V13M10 23V13M10 13H13M10 13H7M23 13H19"
        stroke={"inherit"}
        strokeLinecap="round"
      />
    </symbol>

    {/* <symbol
      id="icon-floorPlan"
      viewBox="0 0 24 25"
      stroke={"inherit"}
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path d="M16 1.5H23V13.5M13 5.5L8 1.5H1V23.5H10M10 23.5H23V13.5M10 23.5V13.5M10 13.5H13M10 13.5H7M23 13.5H19" />
    </symbol> */}

    <symbol id="icon-nabers" viewBox="0 0 24 24">
      <path
        d="M14.8789 9.43506L1.44388 22.8701M22.6571 1.65688C23.0106 5.07457 22.9399 12.6877 17.0002 18.6274C11.0605 24.5671 6.04007 22.0451 4.2723 20.0417C2.26884 18.2739 -0.253178 13.2534 5.68652 7.31374C11.6262 1.37404 19.2394 1.30333 22.6571 1.65688Z"
        stroke={"inherit"}
        strokeLinecap="round"
      />
    </symbol>

    <symbol id="icon-addShortlist" viewBox="0 0 32 33">
      <path
        d="M15.9998 17.5833V9.58333M11.9998 13.5833H19.9998M25.3332 28.25V10.65C25.3332 8.40979 25.3332 7.28969 24.8972 6.43404C24.5137 5.68139 23.9018 5.06947 23.1491 4.68597C22.2935 4.25 21.1734 4.25 18.9332 4.25H13.0665C10.8263 4.25 9.70619 4.25 8.85054 4.68597C8.09789 5.06947 7.48597 5.68139 7.10248 6.43404C6.6665 7.28969 6.6665 8.40979 6.6665 10.65V28.25L15.9998 22.9167L25.3332 28.25Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-removeShortlist" viewBox="0 0 32 32">
      <path
        d="M12.0001 13.3333H20.0001M25.3334 28V10.4C25.3334 8.15979 25.3334 7.03969 24.8974 6.18404C24.5139 5.43139 23.902 4.81947 23.1494 4.43597C22.2937 4 21.1736 4 18.9334 4H13.0667C10.8265 4 9.70643 4 8.85079 4.43597C8.09814 4.81947 7.48622 5.43139 7.10272 6.18404C6.66675 7.03969 6.66675 8.15979 6.66675 10.4V28L16.0001 22.6667L25.3334 28Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-map" viewBox="0 0 25 25">
      <path
        d="M5.5 14.7864C3.64864 15.6031 2.5 16.7412 2.5 18C2.5 20.4853 6.97715 22.5 12.5 22.5C18.0228 22.5 22.5 20.4853 22.5 18C22.5 16.7412 21.3514 15.6031 19.5 14.7864M18.5 8.5C18.5 12.5637 14 14.5 12.5 17.5C11 14.5 6.5 12.5637 6.5 8.5C6.5 5.18629 9.18629 2.5 12.5 2.5C15.8137 2.5 18.5 5.18629 18.5 8.5ZM13.5 8.5C13.5 9.05228 13.0523 9.5 12.5 9.5C11.9477 9.5 11.5 9.05228 11.5 8.5C11.5 7.94772 11.9477 7.5 12.5 7.5C13.0523 7.5 13.5 7.94772 13.5 8.5Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-list" viewBox="0 0 25 25">
      <path
        d="M21.5 12.5L9.5 12.5M21.5 6.5L9.5 6.5M21.5 18.5L9.5 18.5M5.5 12.5C5.5 13.0523 5.05228 13.5 4.5 13.5C3.94772 13.5 3.5 13.0523 3.5 12.5C3.5 11.9477 3.94772 11.5 4.5 11.5C5.05228 11.5 5.5 11.9477 5.5 12.5ZM5.5 6.5C5.5 7.05228 5.05228 7.5 4.5 7.5C3.94772 7.5 3.5 7.05228 3.5 6.5C3.5 5.94772 3.94772 5.5 4.5 5.5C5.05228 5.5 5.5 5.94772 5.5 6.5ZM5.5 18.5C5.5 19.0523 5.05228 19.5 4.5 19.5C3.94772 19.5 3.5 19.0523 3.5 18.5C3.5 17.9477 3.94772 17.5 4.5 17.5C5.05228 17.5 5.5 17.9477 5.5 18.5Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-search" viewBox="0 0 24 24">
      <path
        d="M21 21.5L16.65 17.15M19 11.5C19 15.9183 15.4183 19.5 11 19.5C6.58172 19.5 3 15.9183 3 11.5C3 7.08172 6.58172 3.5 11 3.5C15.4183 3.5 19 7.08172 19 11.5Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    {/* <symbol
      id="icon-document"
      viewBox="0 0 24 25"
      fill={"inherit"}
      stroke={"none"}
    >
      <path d="M14.17 5.5L19 10.33V19.5H5V5.5H14.17ZM14.17 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V10.33C21 9.8 20.79 9.29 20.41 8.92L15.58 4.09C15.21 3.71 14.7 3.5 14.17 3.5ZM7 15.5H17V17.5H7V15.5ZM7 11.5H17V13.5H7V11.5ZM7 7.5H14V9.5H7V7.5Z" />
    </symbol> */}

    <symbol id="icon-play" viewBox="0 0 24 25">
      <path
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9.46533C9.5 8.98805 9.5 8.74941 9.59974 8.61618C9.68666 8.50007 9.81971 8.42744 9.96438 8.4171C10.1304 8.40525 10.3311 8.53429 10.7326 8.79239L15.4532 11.8271C15.8016 12.051 15.9758 12.163 16.0359 12.3054C16.0885 12.4298 16.0885 12.5702 16.0359 12.6946C15.9758 12.837 15.8016 12.949 15.4532 13.1729L10.7326 16.2076C10.3311 16.4657 10.1304 16.5948 9.96438 16.5829C9.81971 16.5726 9.68666 16.4999 9.59974 16.3838C9.5 16.2506 9.5 16.012 9.5 15.5347V9.46533Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>

    <symbol id="icon-image" viewBox="0 0 24 25">
      <path
        d="M4.27209 21.2279L10.8686 14.6314C11.2646 14.2354 11.4627 14.0373 11.691 13.9632C11.8918 13.8979 12.1082 13.8979 12.309 13.9632C12.5373 14.0373 12.7354 14.2354 13.1314 14.6314L19.6839 21.1839M14 15.5L16.8686 12.6314C17.2646 12.2354 17.4627 12.0373 17.691 11.9632C17.8918 11.8979 18.1082 11.8979 18.309 11.9632C18.5373 12.0373 18.7354 12.2354 19.1314 12.6314L22 15.5M10 9.5C10 10.6046 9.10457 11.5 8 11.5C6.89543 11.5 6 10.6046 6 9.5C6 8.39543 6.89543 7.5 8 7.5C9.10457 7.5 10 8.39543 10 9.5ZM6.8 21.5H17.2C18.8802 21.5 19.7202 21.5 20.362 21.173C20.9265 20.8854 21.3854 20.4265 21.673 19.862C22 19.2202 22 18.3802 22 16.7V8.3C22 6.61984 22 5.77976 21.673 5.13803C21.3854 4.57354 20.9265 4.1146 20.362 3.82698C19.7202 3.5 18.8802 3.5 17.2 3.5H6.8C5.11984 3.5 4.27976 3.5 3.63803 3.82698C3.07354 4.1146 2.6146 4.57354 2.32698 5.13803C2 5.77976 2 6.61984 2 8.3V16.7C2 18.3802 2 19.2202 2.32698 19.862C2.6146 20.4265 3.07354 20.8854 3.63803 21.173C4.27976 21.5 5.11984 21.5 6.8 21.5Z"
        stroke={"inherit"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  </svg>
)

export default SvgSprite
