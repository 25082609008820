// import the Action types
import { ActionInterface, CartStateModel } from "../../models/cart"
import { PropertyModel } from "../../models/page"
import { saveLocalStorageCart } from "../../utils/cart"

// calculate the total price of the cart and the total quantity of the cart while saving cart to localStorage
export const sumItems = (cartItems: string[] | undefined) => {
  saveLocalStorageCart(cartItems || [])
  let itemCount = cartItems?.length || 0
  return { itemCount }
}

const CartReducer = (state: CartStateModel, action: ActionInterface) => {
  let payload: any

  switch (action.type) {
    case "LOAD_CART":
      payload = action.payload ? (action.payload as string[]) : undefined
      return {
        ...state,
        cartItems: payload,
        ...sumItems(payload),
        loaded: true,
      }

    case "ADD_TO_CART":
      payload = action.payload as PropertyModel
      const propertyId = payload.propertyId

      let cartItems = [...(state.cartItems || [])]
      if (cartItems?.indexOf(propertyId) === -1) cartItems?.push(propertyId)
      else cartItems = cartItems.filter(x => x !== propertyId)

      return {
        ...state,
        cartItems: cartItems || undefined,
        ...sumItems(cartItems),
      }

    case "REMOVE_ITEM":
      payload = action.payload as number
      if (state.cartItems && state.cartItems?.length > payload)
        state.cartItems?.splice(payload, 1)
      return {
        ...state,
        cartItems: state.cartItems ? [...state.cartItems] : undefined,
        ...sumItems(state.cartItems),
      }

    case "CLEAR":
      return {
        ...state,
        cartItems: [],
        ...sumItems([]),
      }

    // return the state if the action type is not found
    default:
      return state
  }
}

export default CartReducer
