import React, { useEffect } from "react"
import { useParallaxController } from "react-scroll-parallax"

interface Props {
  location: Location
}

const ParallaxRouteUpdate: React.FC<Props> = props => {
  const parallaxController = useParallaxController()

  useEffect(() => {
    parallaxController?.update()
  }, [props.location.pathname])

  return null
}

export default ParallaxRouteUpdate
