/****************************************************

    Utilities - Arrays

****************************************************/

/******************************************************
    Shuffle and array
******************************************************/
export const shuffleArray = (array: any[]) => {
  var currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

/******************************************************
    Intersection of 2 arrays
******************************************************/
export const intersectArrays = (arrA: any[], arrB: any[]) => {
  return arrA.filter(x => arrB.includes(x))
}

/******************************************************
    Difference of 2 arrays
******************************************************/
export const diffArrays = (arrA: any[], arrB: any[]) => {
  return arrA.filter(x => !arrB.includes(x))
}

/******************************************************
    Symmetrical Difference of 2 arrays
******************************************************/
export const symDiffArrays = (arrA: any[], arrB: any[]) => {
  return arrA
    .filter(x => !arrB.includes(x))
    .concat(arrB.filter(x => !arrA.includes(x)))
}

/******************************************************
    Union of 2 arrays - no duplicates
******************************************************/
export const unionArrays = (arrA: any[], arrB: any[]) => {
  return [...new Set([...arrA, ...arrB])]
}

/******************************************************
    Remove duplicates from array of strings
******************************************************/
export const removeDuplicates = (arr: string[]) => {
  return [...new Set(arr)]
}

/******************************************************
	Filter boolean (but for typescript)
  usage: [].filter(filterBoolean)
******************************************************/
type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T
export function filterBoolean<T>(value: T): value is Truthy<T> {
  return !!value
}

/******************************************************
    Get the first item of an array
******************************************************/
export const getFirstArrayItem = (item: any, empty: any = undefined) => {
  if (Array.isArray(item)) {
    if (item.length) return item[0]
    else return empty
  }
  return item
}

/******************************************************
    Check for an empty array
******************************************************/
export const isEmptyArray = (arr: any) => {
  if (Array.isArray(arr)) {
    return arr.length === 0
  }
  return true
}

/******************************************************
	Filter an array by CMS Content Types
******************************************************/
export const getConfigRefs = (items: any[], contentTypes: string[] = []) => {
  if (!items) return []
  return items.filter((x: any) => contentTypes.indexOf(x.internal?.type) !== -1)
}

/******************************************************
	Get first item in Config Ref array
******************************************************/
export const getFirstConfigRef = (
  items: any[],
  contentTypes: string[] = [],
  empty: any = undefined
) => {
  if (!items) return undefined
  return getFirstArrayItem(getConfigRefs(items, contentTypes), empty)
}
