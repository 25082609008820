/****************************************************

	Utilities - Shopping Cart

****************************************************/
import { CartItemModel } from "../models/cart"

/****************************************************
	Session cart
****************************************************/
const cartName = "shortlist"
const VALIDATION = "85jyeN+kl9PyRXadftB4xg=="

/****************************************************
	Cart actions - Fetch
****************************************************/
export const fetchLocalStorageCart = (): CartItemModel[] => {
  const storageData = localStorage.getItem(cartName)

  // If the item is expired, delete from storage return an empty cart
  // const now = new Date()
  // if (!cart.expiry || now.getTime() > cart.expiry) {
  //   return []
  // }

  if (!storageData) return []

  // test for valid cart JSON
  try {
    JSON.parse(storageData)
  } catch (e) {
    return []
  }

  // check the validation string and clear if it doesn't match
  // - allows clearing of all shortlists
  return JSON.parse(storageData).validation !== VALIDATION
    ? []
    : JSON.parse(storageData).cartItems
}

export const fetchCart = async () => {
  const storageCart = fetchLocalStorageCart()

  //   // don't call the API for an empty cart
  //   if (!storageCart.length) return Promise.resolve([])

  //   // axios would crash if the Contentful API failed so using fetch instead
  //   return fetch("/api/getPropertyData", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       items: storageCart.join(","),
  //       preview: process.env.GATSBY_CONTENTFUL_HOST === "preview.contentful.com",
  //     }),
  //   })

  return Promise.resolve(storageCart)
}

/****************************************************
	Cart actions - Save
****************************************************/
export const saveLocalStorageCart = (cartItems: string[]) => {
  if (cartItems) {
    // create new cart expiry
    // const now = new Date()
    const storageData = {
      cartItems: cartItems.length > 0 ? cartItems : [],
      // expiry: now.getTime() + 1000 * 60 * 60 * 24 * 2, // 2 days
      validation: VALIDATION,
    }

    // update localStorage
    localStorage.setItem(cartName, JSON.stringify(storageData))
  }
}
