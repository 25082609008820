/****************************************************

    Utilities

****************************************************/
import { animateScroll as scroll } from "react-scroll"

/******************************************************
    Animate page scroll
******************************************************/
export const scrollToRef = (
  ref: React.RefObject<HTMLElement>,
  offset: number = 0,
  callback: () => void = () => {}
) => {
  if (ref?.current) {
    const top = ref.current.getBoundingClientRect().top - offset
    scroll.scrollMore(top, {
      duration: 0,
      smooth: "linear",
      delay: 0,
    })

    setTimeout(() => callback(), 300)
  }
}

export const convertObjectToCamelCase = (o: any) => {
  var newO: any, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = convertObjectToCamelCase(value)
      }
      return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey)
          .toString()
          .replace(/_/g, " ")
          .replace(/ +/g, " ")
          .replace(/ /g, "")
        value = o[origKey]
        if (
          value instanceof Array ||
          (value !== null && value?.constructor === Object)
        ) {
          value = convertObjectToCamelCase(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}
