/******************************************************

    Utilities - Links

******************************************************/
import { BreadCrumbModel, CmsAsset } from "../models/common"
import { PageModels } from "../models/page"

/******************************************************
	build page breadcrumbs from parent pages
******************************************************/
export const buildBreadcrumbs = (
  page: PageModels | undefined
): BreadCrumbModel[] => {
  if (!page) return []
  if (page?.parentPage)
    return [
      ...buildBreadcrumbs(page.parentPage),
      {
        id: page.id,
        url: page.slug,
        label: page.listingTitle || page.heading,
        rootUrl: page.rootUrl,
      },
    ]
  return [
    {
      id: page.id,
      url: page.slug,
      label: page.listingTitle || page.heading,
      rootUrl: page.rootUrl,
    },
  ]
}

/******************************************************
	build page path from parent pages
******************************************************/
const buildPagePath = (page: PageModels): string | undefined => {
  if (page?.parentPage) return buildPagePath(page.parentPage) + "/" + page.slug
  return page.slug
}

/******************************************************
	get content type link prefix
******************************************************/
const getPageSlugWithPrefix = (page: PageModels) => {
  if (!page || !page.slug) return undefined

  let url
  switch (page?.internal?.type) {
    case "ContentfulPageContent":
      url = `/${buildPagePath(page)}`
      break
    case "ContentfulPageArticle":
      url = `/news/${page.slug}`
      break
    case "ContentfulPageCaseStudy":
      url = `/case-studies/${page.slug}`
      break
    case "ContentfulPageProperty":
      url = `/properties/${page.slug}`
      break
    default:
      url = `/${page.slug}`
  }

  return url
}

/******************************************************
	make the URL pretty
	- add trailing slash (to URL or before query string)
	- remove double slashes (but not protocol eg http://)
	- replace spaces with hyphens
	- remove double hyphens
	- make lowercase
	- add query string
******************************************************/
export const getPrettyURL = (
  data: string | PageModels | undefined
): string | undefined => {
  if (!data) return undefined

  if (typeof data === "string") {
    // break out any query strings parameters
    let urlParts = data.split("?")
    const baseURL = urlParts.shift()?.trim()

    const prettyUrl = (baseURL + "/")
      .replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
      .replace(/\ /g, "-")
      .replace(/\-+/g, "-")
      .toLowerCase()

    if (urlParts.length) return [prettyUrl, urlParts].join("?")
    return prettyUrl
  }

  return getPrettyURL(getPageSlugWithPrefix(data))
}

/******************************************************
	get link data (URL / Entry / Asset)
******************************************************/
interface linkData {
  url?: string | undefined
  page?: PageModels | undefined
  asset?: CmsAsset | undefined
  newWindow?: boolean
  noIndex?: boolean
  noFollow?: boolean
  queryString?: string
}

export const getLinkData = ({
  url,
  page,
  asset,
  newWindow,
  noIndex,
  noFollow,
  queryString,
}: linkData) => {
  // asset
  if (asset) return { url: asset.file?.url, newWindow: true }

  // CMS page
  if (page) {
    const query =
      queryString?.charAt(0) === "?" ? queryString.substring(1) : queryString

    return {
      url: `${getPrettyURL(page)}${queryString ? `?${query}` : ""}`,
      newWindow: newWindow,
      noIndex: noIndex || false,
      noFollow: noFollow || false,
    }
  }

  // URL
  if (url) {
    const isExternal = !/^\/(?!\/)/.test(url)
    return {
      url: url,
      newWindow: newWindow || isExternal,
      noIndex: noIndex || isExternal,
      noFollow: noFollow || isExternal,
    }
  }

  return undefined
}

/******************************************************
	get YouTube video ID
******************************************************/
// export const getYtVideoId = (url?: string): string | undefined => {
//   if (!url) return undefined
//   else {
//     const urlParams = new URLSearchParams(url.split("?").pop())
//     const videoId = urlParams.get("v") || url.split("/").pop()
//     return videoId
//   }
// }

/******************************************************
	make a clean API URL
	- remove double slashes (but not protocol eg http://)
******************************************************/
export const cleanApiURL = (data: string): string => {
  if (!data) return ""
  return data.replace(/(https?:\/\/)|(\/){2,}/g, "$1$2")
}
