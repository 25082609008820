import type { GatsbyBrowser } from "gatsby"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"

/****************************************************
    Site styles
****************************************************/
import "./src/scss/app.scss"

/****************************************************
    Context
****************************************************/
import ArticleProvider from "./src/context/Article/ArticleContext"
import CartState from "./src/context/Cart/CartState"
import CaseStudyProvider from "./src/context/CaseStudy/CaseStudyContext"
import PropertyProvider from "./src/context/Property/PropertyContext"

/****************************************************
    wrap app for global state context
****************************************************/
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => (
  <ParallaxProvider>
    <PropertyProvider>
      <ArticleProvider>
        <CaseStudyProvider>
          <CartState>{element}</CartState>
        </CaseStudyProvider>
      </ArticleProvider>
    </PropertyProvider>
  </ParallaxProvider>
)

/****************************************************
    Page transition 
    - fix scroll jump on page transition
****************************************************/
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const locationPath = location.pathname
  const prevLocationPath = prevRouterProps?.location?.pathname
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  // only scroll top if the page path has changed - not on query string update
  if (locationPath !== prevLocationPath) {
    setTimeout(() => {
      window.scrollTo({
        top: savedPosition[1],
        left: savedPosition[0],
        // behavior: "smooth",
      })
    }, 400)
  }
  return false
}

/****************************************************
    Page change 
****************************************************/
export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {}
