import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { UnitModel } from "../../models/component"
import { PropertyModel } from "../../models/page"
import { cleanApiURL } from "../../utils/links"
import { getUnitDetails } from "../../utils/property"

type ContextModel = {
  propertyItems: PropertyModel[]
  loaded?: boolean
}

const defaultContext: ContextModel = {
  propertyItems: [],
  loaded: false,
}

const initialState = {
  propertyData: defaultContext,
  setPropertyData: (data: ContextModel) => console.log(data),
}
const Context = createContext(initialState)
export const useProperty = () => useContext(Context)

const PROPERTY_API_URL = cleanApiURL(
  process.env.GATSBY_API_DATA + "/api/getProperties"
)

const UNIT_API_URL = cleanApiURL(process.env.GATSBY_API_DATA + "/api/getUnits")

export const PropertyProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [propertyData, setPropertyData] = useState(initialState.propertyData)
  const ContextValue = useMemo(
    () => ({ propertyData, setPropertyData }),
    [propertyData]
  )

  const [properties, setProperties] = useState<PropertyModel[]>()
  const [units, setUnits] = useState<UnitModel[]>()

  const isPreview =
    process.env.GATSBY_CONTENTFUL_HOST === "preview.contentful.com"

  useEffect(() => {
    // get properties
    fetch(PROPERTY_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        preview: isPreview,
      }),
    })
      .then(response => response.json())
      .then(data => setProperties(data))
      .catch(error => {
        setProperties([])
        console.log("Error: ", error)
      })

    // get units
    fetch(UNIT_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        preview: isPreview,
      }),
    })
      .then(response => response.json())
      .then(data => setUnits(data.map((x: any) => getUnitDetails(x))))
      .catch(error => {
        setUnits([])
        console.log("Error: ", error)
      })
  }, [])

  // merge data
  useEffect(() => {
    if (properties && units) {
      const result = properties.map(property => {
        return {
          ...property,
          units: units.filter(unit => unit.propertyId === property.propertyId),
        }
      })

      setPropertyData({
        propertyItems: result,
        loaded: true,
      })
    }
  }, [properties, units])

  return <Context.Provider value={ContextValue}>{children}</Context.Provider>
}

export default PropertyProvider
