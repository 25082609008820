import { createContext, useContext } from "react"
import { CartStateModel } from "../../models/cart"

// initial state of the cart
export const initialCartState: CartStateModel = {
  cartItems: undefined,
  itemCount: undefined,
  loaded: false,
  // expiry: storage.expiry,
}

export const CartContext = createContext<CartStateModel>(initialCartState)
export const useCart = () => useContext(CartContext)
